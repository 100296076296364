import React, { useEffect, useState } from "react"
import useBigScreen from "@/hooks/bigScreen"
import useNavigateLink from "@/hooks/useNavigateLink";
import getCountry from '@/utils/getCountry.js'
import { buy_link } from '@/scripts/constant.js'
import NormalMenu from './NormalMenu.js'
import MobileMenu from './MobileMenu.js'

const Header = ({ menuBlack,dontfixed }) => {
    const bigScreen = useBigScreen(1200)
    const region = getCountry()
    const shopUrl = buy_link[region]['shop']
    const [ifSubMenuShow, setIfSubMenuShow] = useState(false)

    useEffect(() => {
        const cssVariables = {
            "--menu-color": menuBlack || ifSubMenuShow ? "#000" : '#fff',
            "--menu-checkip-textColor": menuBlack ? "#333" : "#fff",
            "--menu-checkip-btnColor": menuBlack ? "rgba(0,0,0,0.8)" : "rgba(255, 255, 255, 0.80)",
            "--menu-checkip-btnHoverColor": menuBlack ? "rgba(255, 255, 255, 0.80)" : "rgba(0,0,0,0.8)",
            "--menu-checkip-background": menuBlack ? "rgba(233, 233, 233, 0.50)" : "rgba(102, 102, 102, 0.50)",
            "--menu-checkip-boderColor": menuBlack ? "rgba(0, 0, 0, 0.10)" : "rgba(255, 255, 255, 0.20)"
        }

        const root = document.documentElement;

        for (const [variable, value] of Object.entries(cssVariables)) {
            root.style.setProperty(variable, value);
        }

    }, [menuBlack, ifSubMenuShow])

    const getLinkUrl = (link) => {
        const url = useNavigateLink(link)
        return url
    }

    return <>
        {bigScreen ?
            <NormalMenu shopUrl={shopUrl} getLinkUrl={getLinkUrl} setIfSubMenuShow={setIfSubMenuShow} ifSubMenuShow={ifSubMenuShow} dontfixed={dontfixed}/>
            :
            <MobileMenu setIfSubMenuShow={setIfSubMenuShow} ifSubMenuShow={ifSubMenuShow} getLinkUrl={getLinkUrl} shopUrl={shopUrl} dontfixed={dontfixed} />
        }

    </>
}

export default Header
