import React from "react"
import {menu_ultra, menu_beampro,}from '@/images/menu/index'
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import {
    Spec_One,
    Spec_OnePro
} from '@/images/one/index'
const region = getCountry()

const submenu_show_pro=[
    {
        name:"ONE",
        img:Spec_One,
        link:"/one",
        sensorID:"",
        gaID:""
    },
    {
        name:"ONE PRO",
        img:Spec_OnePro,
        link:"/one-pro",
        sensorID:"",
        gaID:""
    }
]
const submenu_show_pro_jp=[
    {
        name:"ONE",
        img:Spec_One,
        link:"/one",
        sensorID:"",
        gaID:""
    },
    {
        name:"AIR 2 ULTRA",
        img:menu_ultra,
        link:"/air2ultra",
        sensorID:"",
        gaID:""
    }
]
const submenu_show_pro_else=[
    {
        name:"ONE",
        img:Spec_One,
        link:"/one",
        sensorID:"",
        gaID:""
    },
    {
        name:"AIR 2 ULTRA",
        img:menu_ultra,
        link:"/air2ultra",
        sensorID:"",
        gaID:""
    }
]
const submenu_prolist=[
    {
        type:'menu.glasses',
        pro:[
            {
                name:"One",
                link:"/one",
                gaID:"",
                sensorID:""
            },    {
                name:"One Pro",
                link:"/one-pro",
                gaID:"",
                sensorID:""
            },  
            {
                name:"Air 2 Ultra",
                link:"/air2ultra",
                gaID:"",
                sensorID:""
            },    
            {
                name:"Air 2 / Pro",
                link:"/air2",
                gaID:"",
                sensorID:""
            },
            {
                name:"Air",
                link:"/air",
                gaID:"",
                sensorID:""
            }
        ]
    },{
        type:'BEAM',
        pro:[
            {
                name:"XREAL Beam Pro",
                link:"/beampro",
                gaID:"",
                sensorID:""
            },    
            {
                name:"Beam",
                link:"/beam",
                gaID:"",
                sensorID:""
            }
        ]
    },{
        type:'menu.assessories',
        pro:[
            {
                name:"Hub",
                link:buy_link[region]['hub'].shop,
                gaID:"",
                sensorID:"",
                outlink:true
            },    
            {
                name:"Adapter",
                link:"/adapter",
                gaID:"",
                sensorID:""
            }
        ]
    }
]
const submenu_prolist_jp=[
    {
        type:'menu.glasses',
        pro:[
            {
                name:"One",
                link:"/one",
                gaID:"",
                sensorID:""
            }, 
            {
                name:"Air 2 Ultra",
                link:"/air2ultra",
                gaID:"",
                sensorID:""
            },    
            {
                name:"Air 2 / Pro",
                link:"/air2",
                gaID:"",
                sensorID:""
            },
            {
                name:"Air",
                link:"/air",
                gaID:"",
                sensorID:""
            }
        ]
    },{
        type:'BEAM',
        pro:[
            {
                name:"XREAL Beam Pro",
                link:"/beampro",
                gaID:"",
                sensorID:""
            },    
            {
                name:"Beam",
                link:"/beam",
                gaID:"",
                sensorID:""
            }
        ]
    },{
        type:'menu.assessories',
        pro:[
            {
                name:"Hub",
                link:buy_link[region]['hub'].shop,
                gaID:"",
                sensorID:"",
                outlink:true
            },    
            {
                name:"Adapter",
                link:"/adapter",
                gaID:"",
                sensorID:""
            }
        ]
    }
]
const submenu_prolist_kr=[
    {
        type:'menu.glasses',
        pro:[
            {
                name:"One",
                link:"/one",
                gaID:"",
                sensorID:""
            }, 
            {
                name:"Air 2 Ultra",
                link:"/air2ultra",
                gaID:"",
                sensorID:""
            },    
            {
                name:"Air 2 / Pro",
                link:"/air2",
                gaID:"",
                sensorID:""
            },
            {
                name:"Air",
                link:"/air",
                gaID:"",
                sensorID:""
            }
        ]
    },{
        type:'BEAM',
        pro:[
            {
                name:"XREAL Beam Pro",
                link:"/beampro",
                gaID:"",
                sensorID:""
            },    
            {
                name:"Beam",
                link:"/beam",
                gaID:"",
                sensorID:""
            }
        ]
    },{
        type:'menu.assessories',
        pro:[
           
            {
                name:"Adapter",
                link:"/adapter",
                gaID:"",
                sensorID:""
            }
        ]
    }
]

export {
    submenu_prolist_jp,
    submenu_prolist,
    submenu_show_pro,
    submenu_prolist_kr,
    submenu_show_pro_else,
    submenu_show_pro_jp
}