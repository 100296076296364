import React, { useRef, useEffect, useState } from "react";
import style from "./index.module.less"
import { Link } from "gatsby"
import {
    XrealLogo
} from "@/images/menu"
import CartSVG from "@/images/SVG/cart.svg"
import ShopIconSVG from "@/images/SVG/shopicon.svg"
import useGa from '@/hooks/useGa'
import classNames from 'classnames'
import CheckIpBox from './checkip.js'
import { submenu_prolist, submenu_show_pro, submenu_prolist_kr ,submenu_prolist_jp,submenu_show_pro_else,submenu_show_pro_jp} from './const_menulist.js'
import { useIntl } from "react-intl";
import getCountry from '@/utils/getCountry.js'
import { useGTM } from '@/hooks/useGTM.js'


const NormalMenu = ({ getLinkUrl, shopUrl, setIfSubMenuShow, ifSubMenuShow, dontfixed }) => {
    const menuRef = useRef(null)
    const headerRef = useRef(null)
    const [hoverSubmenu, setHoverSubmenu] = useState(false);
    const [hoverPro, setHoverPro] = useState(false);
    const region = getCountry()
    const menu_list = region == 'kr'? submenu_prolist_kr :(region=='jp'?submenu_prolist_jp:submenu_prolist)
    const showList=region=='kr'?submenu_show_pro_else:(region=='jp'?submenu_show_pro_jp:submenu_show_pro)
    const intl = useIntl()
    useEffect(() => {
        if (!hoverPro && !hoverSubmenu) {
            setIfSubMenuShow(false)
        } else {
            setIfSubMenuShow(true)
        }
    }, [hoverPro, hoverSubmenu])

    useEffect(() => {
        document.body.style.overflow = 'auto'
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    let lastScrollTop = 0;
    const handleScroll = () => {
        const currentScrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        const scrollDistance = currentScrollTop - lastScrollTop
        lastScrollTop = currentScrollTop;
        const whentohide = 200
        if (scrollDistance > 0 && currentScrollTop >= whentohide) {
            menuRef.current.style.opacity = '0'
            headerRef.current.style.transform = `translateY(-100%)`
        } else if (currentScrollTop < whentohide) {
            menuRef.current.style.opacity = '1'
            headerRef.current.style.transform = `translateY(0)`
        } else {
            if (!dontfixed) {
                menuRef.current.style.opacity = '1'
                headerRef.current.style.transform = `translateY(0)`
            }

        }
    }



    const addGa = (cate) => {
        useGa('Menu', cate, 'Click')
    }
    const addSensor = (id, product_name) => {
        if (!!product_name) {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id,
                    products_name: product_name
                }
            });
        } else {
            typeof sensors !== "undefined" && sensors.track('module_interaction', {
                custom: {
                    module_id: id
                }
            });
        }

    }
    return (
        <div className={style.header} style={{
            boxShadow: ifSubMenuShow ? 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px' : 'none',
            position: dontfixed ? "fixed" : "fixed"
        }}

            ref={headerRef}>
            <CheckIpBox ifPc={true} />
            <div className={style.menu} style={{ backgroundColor: ifSubMenuShow ? '#fff' : "transparent" }} ref={menuRef}>
                <div className={style.menuLeft} >
                    <div className={classNames(style.pro, style.menuTitle)} style={{ color: ifSubMenuShow ? '#FF2929' : '' }}
                        onMouseEnter={() => {
                            setHoverPro(true),
                            useGTM('navigation_click', 'PRODUCTS', '', 'top navigation')
                        }}
                        onMouseLeave={() => { setHoverPro(false) }}
                    >
                        {intl.formatMessage({ id: "menu.products" })}
                    </div>
                    <Link to={getLinkUrl("/ar")} className={style.menuTitle} onClick={() => { addGa('menu_ar'), addSensor(10002), useGTM('navigation_click', 'AR', '', 'top navigation') }}>
                        {intl.formatMessage({ id: "menu.ar" })}
                    </Link>

                </div>
                <Link to={getLinkUrl("/")} className={style.menuLogo} onClick={() => { addGa('menu_home'), addSensor(10000), useGTM('navigation_click', 'XREAL', '', 'top navigation') }}>
                    <XrealLogo />
                </Link>

                <div className={style.menuRight}>
                    <Link to={getLinkUrl("/support")} className={style.menuTitle} onClick={() => { addGa('menu_support'), addSensor(10003), useGTM('navigation_click', 'SUPPORT', '', 'top navigation') }}>
                        {intl.formatMessage({ id: "menu.support" })}
                    </Link>
             
                    <a href={shopUrl} target='_blank' className={style.cart} onClick={() =>{ addGa('menu_shop'), addSensor(10004),useGTM('navigation_click','SHOP','','top navigation')}}>
                      <ShopIconSVG />
                        <span> {intl.formatMessage({ id: "menu_shop" })}</span>
                       
                       
                    </a>

                </div>
            </div>

            <div className={style.submenu} style={{ maxHeight: ifSubMenuShow ? '500px' : '0', opacity: ifSubMenuShow ? '1' : '0' }}
                onMouseEnter={() => { setHoverSubmenu(true) }}
                onMouseLeave={() => { setHoverSubmenu(false) }}
            >
                <div className={style.submenuBox}>
                    <div className={style.submenuBoxLeft}>
                        {
                            showList.map((item, index) => (
                                <Link to={getLinkUrl(item.link)} className={style.showPro} key={`menu-showpro-${index}`} onClick={() => {
                                    addGa(item.gaID)
                                        , addSensor(item.sensorID)
                                    useGTM('navigation_click', `image_${item.name}`, '', 'top navigation')
                                }}>
                                    <div className={style.showProImg}>
                                        <img src={item.img} />
                                    </div>

                                    <div className={style.showProName}>{item.name}</div>
                                </Link>
                            ))
                        }
                    </div>
                    <div className={style.submenuBoxRight}>
                        {menu_list.map((item, index) => (
                            <div className={style.prolist} key={`menu-pro-list${index}`}>
                                <div className={style.prolistType}> {intl.formatMessage({ id: item.type })}</div>
                                <div className={style.prolistBox}>
                                    {item.pro.map((value, key) => (
                                        <div key={`prolistBox${item.type}${key}`}
                                            className={style.prolistBoxItem}
                                            onClick={() => useGTM('navigation_click', value.name, '', 'top navigation')}
                                        >
                                            {!!value.outlink ? <a href={value.link} target='_blank'>
                                                {value.name}
                                            </a> : <Link to={getLinkUrl(value.link)}
                                                onClick={() => { addGa(value.gaID), addSensor(value.sensorID) }}
                                            >
                                                {value.name}
                                            </Link>}
                                        </div>

                                    ))}
                                    {index == 0 && region!=='jp'&&region!=='kr'&&<Link className={style.compare} to={getLinkUrl('/glasses/compare')} >
                                        Compare Glasses
                                    </Link>}

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NormalMenu